import React, { useEffect, useRef } from "react";
import s from "./BgLine1.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
gsap.registerPlugin(DrawSVGPlugin);

export const BgLine1 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear"
      }
    );
  }, []);

  return (
    <div id="hero__line" className={s.hero__line}>
      <svg
        width="2000"
        height="900"
        viewBox="0 0 2000 900"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={lineRef}
          fill="none"
          strokeMiterlimit="10"
          d="M289.3,0v114.2c0,79.5-64.5,144-144,144l0,0C65.8,258.2,1,322.7,1,402.2L1,799c0,55.2,44.8,100,100,100h1899"
          opacity="0.2"
        />
      </svg>
    </div>
  );
};
